<template>
  <form @submit.prevent="setPassword">
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <p class="modal-card-title has-text-white has-text-centered">
          {{ action }} password
        </p>
      </header>

      <section class="modal-card-body">
        <create-password
          :disabled="processing"
          confirm
          autofocus
          @validated="password = $event"
        />
      </section>

      <footer class="modal-card-foot has-content-justified-between">
        <button
          :disabled="processing"
          type="button"
          class="button is-light"
          tabindex="-1"
          @click="$emit('close')"
        >
          Cancel
        </button>

        <button
          :class="{ 'is-loading': processing }"
          :disabled="formIsDisabled"
          type="submit"
          class="button is-success"
        >
          {{ action }} password
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "PasswordModal",
  components: {
    "create-password": () => import("@shared/account/_createPassword")
  },
  props: {
    hasPassword: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      password: ""
    };
  },
  computed: {
    action() {
      return this.hasPassword ? "Change" : "Set";
    },
    formIsValid() {
      return !!this.password.length;
    },
    formIsDisabled() {
      return !this.formIsValid || this.processing;
    }
  },
  methods: {
    setPassword() {
      if (this.formIsDisabled) return;
      this.processing = true;
      this.$store
        .dispatch(
          `auth/${this.hasPassword ? "changePassword" : "setPassword"}`,
          { password: this.password }
        )
        .then(result => {
          this.$emit("set");
          if (result && result.message) {
            this.$toast.open({
              message: `${result.message}`,
              position: "is-bottom",
              type: "is-success",
              queue: false
            });
          }
        })
        .catch(error => {
          this.processing = false;
          if (error && error.message) {
            this.$toast.open({
              message: `${error.message}`,
              position: "is-bottom",
              type: "is-danger",
              queue: false,
              duration: 3000
            });
          }
        });
    }
  }
};
</script>
